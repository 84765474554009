require('leaflet');

const soilMoistureVeryHigh = require('../images/markers/soil-moisture/1.png');
const soilMoistureHigh = require('../images/markers/soil-moisture/2.png');
const soilMoistureModerate = require('../images/markers/soil-moisture/3.png');
const soilMoistureLow = require('../images/markers/soil-moisture/4.png');
const soilMoistureVeryLow = require('../images/markers/soil-moisture/5.png');

const waterLevelAlarm = require('../images/markers/water-level/1.png');
const waterLevelWarning = require('../images/markers/water-level/2.png');
const waterLevelHigh = require('../images/markers/water-level/3.png');
const waterLevelAverage = require('../images/markers/water-level/4.png');
const waterLevelLow = require('../images/markers/water-level/5.png');

const giosVeryGood = require('../images/markers/gios/very-good.png');
const giosGood = require('../images/markers/gios/good.png');
const giosModerate = require('../images/markers/gios/moderate.png');
const giosSatisfactory = require('../images/markers/gios/satisfactory.png');
const giosBad = require('../images/markers/gios/bad.png');
const giosVeryBad = require('../images/markers/gios/very-bad.png');
const giosNoData = require('../images/markers/gios/no-data.png');

const waterTemp = require('../images/markers/water-temp.svg');

const lifeBuoy = require('../images/markers/ios/lifebuoy.svg');

const bicycle = require('../images/markers/bicycle.png');

var window_width = window.innerWidth;
let scale = 1;

if(window_width < (document.getElementById('airsensor-map').getAttribute('mobileBreakpoint') || 992)){
    // scale *= (1 + ((992 - window_width)/992) * 1);
    scale = 1.4;
}

module.exports.scale = scale;

module.exports.waterTemp = L.icon({
    iconUrl: waterTemp.default,

    iconSize:     [36*scale, 46*scale], // size of the icon
    iconAnchor:   [10*scale, 20*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [4*scale, -2*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.lifeBuoy = L.icon({
    iconUrl: lifeBuoy.default,

    iconSize:     [1.5*36*scale, 1.5*46*scale], // size of the icon
    iconAnchor:   [1.5*18*scale, 1.5*18*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [2*scale, -10*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.soilMoistureVeryHigh = L.icon({
    iconUrl: soilMoistureVeryHigh.default,

    iconSize:     [26*scale, 36*scale], // size of the icon
    iconAnchor:   [10*scale, 20*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [4*scale, -2*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.soilMoistureHigh = L.icon({
    iconUrl: soilMoistureHigh.default,

    iconSize:     [26*scale, 36*scale], // size of the icon
    iconAnchor:   [10*scale, 20*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [4*scale, -2*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.soilMoistureModerate = L.icon({
    iconUrl: soilMoistureModerate.default,

    iconSize:     [26*scale, 36*scale], // size of the icon
    iconAnchor:   [10*scale, 20*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [4*scale, -2*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.soilMoistureLow = L.icon({
    iconUrl: soilMoistureLow.default,


    iconSize:     [26*scale, 36*scale], // size of the icon
    iconAnchor:   [10*scale, 20*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [4*scale, -2*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.soilMoistureVeryLow = L.icon({
    iconUrl: soilMoistureVeryLow.default,

    iconSize:     [26*scale, 36*scale], // size of the icon
    iconAnchor:   [10*scale, 20*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [4*scale, -2*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.waterLevelAlarm = L.icon({
    iconUrl: waterLevelAlarm.default,

    iconSize:     [32*scale, 28*scale],   // size of the icon
    iconAnchor:   [12*scale, 15.5*scale], // poin of the icon which will correspond to markers's location
    popupAnchor:  [4*scale, -2*scale]    // point from which the popup should open relative to the iconAnchor
});

module.exports.waterLevelWarning = L.icon({
    iconUrl: waterLevelWarning.default,

    iconSize:     [32*scale,28*scale],   // size of the icon
    iconAnchor:   [12*scale, 15.5*scale], // poin of the icon which will correspond to markers's location
    popupAnchor:  [4*scale, -2*scale]    // point from which the popup should open relative to the iconAnchor
});

module.exports.waterLevelHigh = L.icon({
    iconUrl: waterLevelHigh.default,

    iconSize:     [32*scale, 28*scale],   // size of the icon
    iconAnchor:   [12*scale, 15.5*scale], // poin of the icon which will correspond to markers's location
    popupAnchor:  [4*scale, -2*scale]    // point from which the popup should open relative to the iconAnchor
});

module.exports.waterLevelAverage = L.icon({
    iconUrl: waterLevelAverage.default,

    iconSize:     [32*scale, 28*scale],   // size of the icon
    iconAnchor:   [12*scale, 15.5*scale], // poin of the icon which will correspond to markers's location
    popupAnchor:  [4*scale, -2*scale]    // point from which the popup should open relative to the iconAnchor
});

module.exports.waterLevelLow = L.icon({
    iconUrl: waterLevelLow.default,

    iconSize:     [32*scale, 28*scale],   // size of the icon
    iconAnchor:   [12*scale, 15.5*scale], // poin of the icon which will correspond to markers's location
    popupAnchor:  [4*scale, -2*scale]    // point from which the popup should open relative to the iconAnchor
});

module.exports.giosVeryGood = L.icon({
    iconUrl: giosVeryGood.default,

    iconSize:     [20*scale, 20*scale], // size of the icon
    iconAnchor:   [10*scale, 10*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [0*scale, -9*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.giosGood = L.icon({
    iconUrl: giosGood.default,

    iconSize:     [20*scale, 20*scale], // size of the icon
    iconAnchor:   [10*scale, 10*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [0*scale, -9*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.giosModerate = L.icon({
    iconUrl: giosModerate.default,

    iconSize:     [20*scale, 20*scale], // size of the icon
    iconAnchor:   [10*scale, 10*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [0*scale, -9*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.giosSatisfactory = L.icon({
    iconUrl: giosSatisfactory.default,

    iconSize:     [20*scale, 20*scale], // size of the icon
    iconAnchor:   [10*scale, 10*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [0*scale, -9*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.giosBad = L.icon({
    iconUrl: giosBad.default,

    iconSize:     [20*scale, 20*scale], // size of the icon
    iconAnchor:   [10*scale, 10*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [0*scale, -9*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.giosVeryBad = L.icon({
    iconUrl: giosVeryBad.default,

    iconSize:     [20*scale, 20*scale], // size of the icon
    iconAnchor:   [10*scale, 10*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [0*scale, -9*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.giosNoData = L.icon({
    iconUrl: giosNoData.default,

    iconSize:     [20*scale, 20*scale], // size of the icon
    iconAnchor:   [10*scale, 10*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [0*scale, -9*scale] // point from which the popup should open relative to the iconAnchor
});

module.exports.bicycle = L.icon({
    iconUrl: bicycle.default,

    iconSize:     [20*scale, 20*scale], // size of the icon
    iconAnchor:   [5*scale, 10*scale], // point of the icon which will correspond to marker's location
    popupAnchor:  [4*scale, -2*scale] // point from which the popup should open relative to the iconAnchor
});