import urls from '../../urls.json'

require('leaflet');
require('leaflet/dist/leaflet.css');
const logo_ekoslupek = require('../images/logo_ekoslupek.png');
const logo_egminy = require('../images/logo_e-gminy.png');

const mapDiv = document.getElementById('airsensor-map');

import marker from './leaflet-marker';

import '../map_styles.scss';
import dateFormat from 'dateformat';

// Set map bounds
let corner1 = L.latLng(56.8917, 14.0770);
let corner2 = L.latLng(48.5340, 24.6308);
if(mapDiv.getAttribute('bounds')){
    let corners = mapDiv.getAttribute('bounds').replace(/\s+/g, '').split('),(');
    corner1 = corners[0].slice(1, corners[0].length-1).split(',');
    corner2 = corners[1].slice(0, -1).split(',');
    corner1 = L.latLng(corner1[0], corner1[1]);
    corner2 = L.latLng(corner2[0], corner2[1]);
}

const widgetSettings = {
    "public": mapDiv.getAttribute('public') || "true",
    "ios": mapDiv.getAttribute('ios') || "true",
    "gios": mapDiv.getAttribute('gios') || "false",
    "air": mapDiv.getAttribute('air') || "true",
    "legend": mapDiv.getAttribute('legend') || "true",
    "imageUrl": mapDiv.getAttribute('imageUrl'),
    "imageWidth": mapDiv.getAttribute('imageWidth'),
    "imageHeight": mapDiv.getAttribute('imageHeight'),
    "imageLink": mapDiv.getAttribute('imageLink'),
};

const maxBounds = L.latLngBounds(corner1, corner2);

let mymap = L.map('airsensor-map', {
    zoomControl: false,
    maxBounds: maxBounds,
    maxZoom: mapDiv.getAttribute('maxZoom') || 19,
    minZoom: mapDiv.getAttribute('minZoom') || 6,
    scrollWheelZoom: false,
});

// Map zooming with scroll
mapDiv.addEventListener('wheel', function(e){
    if(document.activeElement === mapDiv){
        if(e.deltaY > 0){
            mymap.zoomOut();
        }
        else{
            mymap.zoomIn();
        }
        e.preventDefault();
    }
});

mymap.setView([mapDiv.getAttribute('latitude') || 49.7688, mapDiv.getAttribute('longitude') || 19.2769], mapDiv.getAttribute('zoom') || 9);

L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
}).addTo(mymap);

let markersLayer = new L.LayerGroup();
mymap.addLayer(markersLayer);

// Set urls
const proxyUrl = (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') ? (urls.developmentProxy + ':' + urls.proxyPort) : urls.productionProxy;
const mySensorsUrl = proxyUrl + '/sensors';
const mySensorsPublicUrl = proxyUrl + '/sensors/public';
const sensorInfoUrl = proxyUrl + '/sensor/';
const myGiosSensorsUrl = proxyUrl + '/gios/sensors';
const myGiosSensorsInfoUrl = proxyUrl + '/gios/sensor/';

L.control.zoom({
    position: 'topright'
}).addTo(mymap);

let getResultUnit = function(type_name){
    switch(type_name){
        case 'pm_10_0':
            return 'µg/m³';
        case 'pm_2_5':
            return 'µg/m³';
        case 'pm_1_0':
            return 'µg/m³';
        case 'temperature':
            return '°C';
        case 'pressure':
            return 'hPa';
        case 'humidity':
            return '%';
        case 'formaldehyde':
            return 'µg/m³';
        case 'soil_moisture':
            return '%';
        case 'nitrogen_dioxide':
            return 'µg/m³';
        case 'sulfur_dioxide':
            return 'µg/m³';
        case 'ozone':
            return 'µg/m³';
        case 'carbon_dioxide':
            return 'ppm';
        case 'water_level':
            return 'cm';
        case 'ozone':
            return 'µg/m³';
        case 'c6h6':
            return 'µg/m³';
        case 'co':
            return 'µg/m³';
        case 'soil_moisture':
            return '%';
        case 'battery_level':
            return '%';
        case 'water_temperature':
            return '°C';
        case 'water_ph':
            return '';
        case 'electro_conduction':
            return 'µS/cm';
        case 'water_oxygen_percent':
            return '%';
        case 'water_oxygen':
            return 'mg/l';
        case 'blue_green_algae':
            return 'cells/mL';
        case 'turbidity':
            return 'NTU';
        case 'chlorophyll':
            return 'μg/L';
        default:
            return '';
    }
};

let getResultIndex = function(type_name){
    switch(type_name){
        case 'pm_10_0':
            return 1;
        case 'pm_2_5':
            return 2;
        case 'pm_1_0':
            return 3;
        case 'temperature':
            return 30;
        case 'pressure':
            return 31;
        case 'humidity':
            return 32;
        case 'formaldehyde':
            return 4;
        case 'soil_moisture':
            return 5;
        case 'nitrogen_dioxide':
            return 5;
        case 'sulfur_dioxide':
            return 5;
        case 'ozone':
            return 5;
        case 'carbon_dioxide':
            return 5;
        case 'water_level':
            return 5;
        case 'ozone':
            return 5;
        case 'c6h6':
            return 5;
        case 'co':
            return 5;
        default:
            return 5;
    }
};

let getResultName = function(type_name){
    switch(type_name){
        case 'pm_10_0':
            return 'PM&nbsp;10.0';
        case 'pm_2_5':
            return 'PM&nbsp;2.5';
        case 'pm_1_0':
            return 'PM&nbsp;1.0';
        case 'temperature':
            return 'Temperatura';
        case 'pressure':
            return 'Ciśnienie';
        case 'humidity':
            return 'Wilgotność';
        case 'formaldehyde':
            return 'HCHO';
        case 'nitrogen_dioxide':
            return 'NO₂';
        case 'sulfur_dioxide':
            return 'SO₂';
        case 'water_level':
            return 'Poziom&nbsp;wody';
        case 'carbon_dioxide':
            return 'CO₂';
        case 'ozone':
            return 'O<sub>3</sub>';
        case 'c6h6':
            return 'C<sub>6</sub>H<sub>6</sub>';
        case 'co':
            return 'CO';
        case 'soil_moisture':
            return 'Wilg.&nbsp;gleby';
        case 'battery_level':
            return 'Poziom baterii';
        case 'bicycles_count':
            return 'Ilość rowerów';
        case 'water_temperature':
            return 'Temperatura wody';
        case 'water_ph':
            return 'Odczyn Ph';
        case 'electro_conduction':
            return 'Przewodność';
        case 'water_oxygen_percent':
            return 'Natlenienie';
        case 'water_oxygen':
            return 'Natlenienie';
        case 'blue_green_algae':
            return 'Algi B/G';
        case 'turbidity':
            return 'Mętność';
        case 'chlorophyll':
            return 'Chlorofil';
        default:
            return type_name;
    }
};

const getLastAverageHour = function(sensor_data){
    let history = sensor_data.history;
    let lastAverageHour = '1990-01-01 01:00';
    let secondlastAverageHour = '1990-01-01 01:00';
    for(const hour in history){
        if(new Date(lastAverageHour) < new Date(hour)){
            secondlastAverageHour = lastAverageHour;
            lastAverageHour = hour;
        }
    }

    return [lastAverageHour, secondlastAverageHour];
};

const updateSensorClassifer = function(sensor_data){
    
};

const getWaterLevelclassifer = function(score){
    if(score <= 34){
        return "Stan niski";
    }
    else if(score <= 54){
        return "Stan średni";
    }
    else if(score <= 99){
        return "Stan wysoki";
    }
    else if(score <= 155){
        return "Stan ostrzegawczy";
    }
    else if(score > 155){
        return "Stan alarmowy";
    }
    else{
        return "";
    }
};

const updatePopupContent = function (sensor_data){
    const lastAverageHour = getLastAverageHour(sensor_data);

    let popUpContent = '<div style="font-size:' + (12 * marker.scale) + 'px; display: flex; flex-direction: column;"><div>' + sensor_data.sensor.name.replace(/\s/g, '&nbsp;') + '</div>'
    + '<div class="air-sensor-quality-bar" style="background-color: '
    + marker.getMarkerColor(sensor_data.classifier.class);

    const results = sensor_data.latest.results;
    const history = sensor_data.history;

    let label;
    if(sensor_data.classifier.label !== undefined){
        label =  ';">' + sensor_data.classifier.label + '</div>';
    }
    else{
        label =  ';"></div>';
    }
    if(results){
        for(let i = 0; i < results.length; i++){
            if(results[i].type == "water_level"){
                let score = history[lastAverageHour[0]][results[i].type];
                if(score === null){
                    if(history[lastAverageHour[1]][results[i].type] === null){
                        continue;
                    }
                    else{
                        score = history[lastAverageHour[1]][results[i].type];
                    }
                }
                label =  ';">' + getWaterLevelclassifer(score) + '</div>';
            }
        }
    }
    popUpContent += label;

    if(results){
        for(let i = 0; i < results.length; i++){
            let score = history[lastAverageHour[0]][results[i].type];
            if(score === null || score === undefined){
                if(history[lastAverageHour[1]][results[i].type] === null || history[lastAverageHour[1]][results[i].type] === undefined){
                    if(results[i].type == "bicycles_count"){
                        score = 0;
                    }
                    else{
                        continue;
                    }
                }
                else{
                    score = history[lastAverageHour[1]][results[i].type];
                }
            }
            if(results[i].type != "bicycles_count"){
                popUpContent += '<div style="min-width: 200px; text-align: center; order: ' + getResultIndex(results[i].type) + '"><div style="display: inline-block; width: 33%">'
                + getResultName(results[i].type).replace(' ', '&nbsp;') + '</div><div style="display: inline-block; width: 33%">';
                if(results[i].norm){
                    if(results[i].type == "pm_10_0"){
                        popUpContent += Math.round((score / 50)*100) + '%';
                    }
                    else if(results[i].type == "pm_2_5"){
                        popUpContent += Math.round((score / 25)*100) + '%';
                    }
                }
                popUpContent += '</div><div style="display: inline-block; width: 33%">' + score + '&nbsp;' + getResultUnit(results[i].type) + '</div></div>';
            }
            else{
                popUpContent += '<div style="min-width: 200px; text-align: center; order: ' + getResultIndex(results[i].type) + '"><div style="display: inline-block; width: 100%">'
                + "Liczba rowerzystów na trasie z ostatniej godziny".replace(' ', '&nbsp;') + '</div><div style="display: inline-block; width: 100%">';
                if(results[i].norm){
                    if(results[i].type == "pm_10_0"){
                        popUpContent += Math.round((score / 50)*100) + '%';
                    }
                    else if(results[i].type == "pm_2_5"){
                        popUpContent += Math.round((score / 25)*100) + '%';
                    }
                }
                popUpContent += '</div><div style="display: inline-block; width: 100%; font-size:20px;">' + score + '&nbsp;' + getResultUnit(results[i].type) + '</div><div style="display: inline-block; width: 100%">&nbsp;' + getResultUnit(results[i].type) + '</div></div>';
            }
        }
    }

    if(sensor_data.classifier.type == 'gios'){
        popUpContent += '<div style="order: 1000000; text-align: center;">Źródło: <a href="https://www.gios.gov.pl/pl/">GIOŚ</a></div>';
    }
    else{
        popUpContent += '<div style="order: 1000000; text-align: center;">Źródło: <a href="https://e-gminy.pl/">Airsensor</a></div>';
    }

    return popUpContent;
};

let markerClick = function (e) {
    fetch(sensorInfoUrl + this.options.id, {
        headers: {
            site: document.getElementById('airsensor-map').getAttribute('site') || location.host,
            apiKey: document.getElementById('airsensor-map').getAttribute('clientid')
        }
    })
    .then(function (response) {
        return response.json()
    })
    .then(updatePopupContent)
    .then(function(popUpContent){
        e.target.setPopupContent(popUpContent);
    });
};

let giosMarkerClick = function (e) {
    fetch(myGiosSensorsInfoUrl + this.options.id, {
        headers: {
            site: document.getElementById('airsensor-map').getAttribute('site') || location.host,
            apiKey: document.getElementById('airsensor-map').getAttribute('clientid')
        }
    })
    .then(function (response) {
        return response.json()
    })
    .then(updatePopupContent)
    .then(function(popUpContent){
        e.target.setPopupContent(popUpContent);
    })
};

const addLogos = function () {
    // "imageUrl": mapDiv.getAttribute('imageUrl') || '',
    // "imageWidth": mapDiv.getAttribute('imageWidth'),
    // "imageHeight": mapDiv.getAttribute('imageHeight')
    // Add logo ekoslupek
    const ekoslupekLink = document.createElement("a");
    ekoslupekLink.href = 'https://ekoslupek.pl';
    ekoslupekLink.classList.add('air-sensor-ekoslupek-link');
    const image = document.createElement("img");

    // Set logo
    if(widgetSettings.imageUrl !== null){
        image.src = widgetSettings.imageUrl;
        if(widgetSettings.imageLink !== null){
            ekoslupekLink.href = widgetSettings.imageLink;
        }
    }
    else{
        image.src = logo_ekoslupek.default;
    }

    if(widgetSettings.imageWidth !== null){
        image.style.width = widgetSettings.imageWidth;
    }
    if(widgetSettings.imageHeight !== null){
        image.style.height = widgetSettings.imageHeight;
    }

    ekoslupekLink.appendChild(image);
    mapDiv.appendChild(ekoslupekLink);

    // Add logo e-gminy.pl
    const egminyLink = document.createElement("a");
    egminyLink.href = 'https://e-gminy.pl';
    egminyLink.classList.add('air-sensor-egminy-link');
    const image_egminy = document.createElement("img");
    image_egminy.src = logo_egminy.default;
    egminyLink.appendChild(image_egminy);
    mapDiv.appendChild(egminyLink);
}

const addLegend = function () {
    if(widgetSettings.legend === 'false'){
        // Skip legend
        return;
    }

    // Add legend
    const legend = document.createElement("div");
    legend.classList.add('air-sensor-ekoslupek-legend');
    legend.innerHTML = '<div id="air-sensor-ekoslupek-button-shadow"><div id="air-sensor-ekoslupek-button">i</div></div>' + 
    '<div id="air-sensor-ekoslupek-colors-shadow"><div id="air-sensor-ekoslupek-colors"><div class="air-sensor-legend-bar" style="background-color: #FFFFFF; color: black;">Stan&nbsp;zanieczyszczeń w&nbsp;powietrzu</div>' +
    '<div class="air-sensor-legend-bar" style="background-color: #4B9608;">Bardzo&nbsp;dobry</div>' +
    '<div class="air-sensor-legend-bar" style="background-color: #A2CE10;">Dobry</div>' +
    '<div class="air-sensor-legend-bar" style="background-color: #F0CB10;">Umiorkowany</div>' +
    '<div class="air-sensor-legend-bar" style="background-color: #CE7200;">Dostateczny</div>' +
    '<div class="air-sensor-legend-bar" style="background-color: #CB0000;">Zły</div>' +
    '<div class="air-sensor-legend-bar" style="background-color: #830000;">Bardzo&nbsp;zły</div></div>' +
    '</div>'+
    '<div style="clear: both;"></div>'
    ;
    mapDiv.appendChild(legend);

    legend.addEventListener('click', function(e){
        const colorsDiv = document.querySelector('.air-sensor-ekoslupek-legend');
        if(colorsDiv.style.right == '0px'){
            // Show
            colorsDiv.animate([{right: '0px'}, {right: '-152px'}], {duration: 200});
            colorsDiv.style.right = '-152px';
        }
        else{
            // Hide
            colorsDiv.animate([{right: '-152px'}, {right: '0px'}], {duration: 200});
            colorsDiv.style.right = '0px';
        }
    });
}

const buildMarkersLayer = (sensors) => {
    // Filter sensor types
    const sensorTypeIds = [];
    if(widgetSettings.air === 'true'){
        sensorTypeIds.push(1);
        sensorTypeIds.push(2);
    }
    if(widgetSettings.ios === 'true'){
        sensorTypeIds.push(7);
    }

    for (let i = 0; i < sensors.length; i++) {
        if(!sensorTypeIds.includes(sensors[i].sensor.type)){
            continue;
        }
        if(sensors[i].classifier.class === "no-data" ||
            !(maxBounds.contains(L.latLng(sensors[i].sensor.longitude, sensors[i].sensor.latitude)))){
            continue;
        }

        // Check if sensor type is bicycle
        let bicycle = false;
        for(let j = 0; j < sensors[i].sensor.resultTypes.length; j++){
            if(sensors[i].sensor.resultTypes[j].name == "bicycles_count"){
                bicycle = true;
                break;
            }
        }

        let sensor;
        if(!bicycle){
            sensor = marker.createMarker(sensors[i], mymap)
            .bindPopup('', {closeButton: false, maxWidth: 1000})
            .setPopupContent(sensors[i].sensor.name.replace(/\s/g, '&nbsp;'))
            .on('click', markerClick);
        }
        else{
            sensor = marker.createBicycleMarker(sensors[i], mymap)
            .bindPopup('', {closeButton: false, maxWidth: 1000})
            .setPopupContent(sensors[i].sensor.name.replace(/\s/g, '&nbsp;'))
            .on('click', markerClick);
        }

        markersLayer.addLayer(sensor);
        globalSensors[sensors[i].sensor.id] = sensor;
    }

}

let sensorDetailsTimeout = null;

let globalSensors = [];
let globalGiosSensors = [];

let loadMapMarkers = function() {
    markersLayer.clearLayers();
    fetch(mySensorsUrl, {
        headers: {
            site: document.getElementById('airsensor-map').getAttribute('site') || location.host,
            apiKey: document.getElementById('airsensor-map').getAttribute('clientid')
        }
    }).then(function (response) {
        return response.json()
    }).then(function (sensors) {
        buildMarkersLayer(sensors);
    });

    fetch(mySensorsPublicUrl, {
        headers: {
            site: document.getElementById('airsensor-map').getAttribute('site') || location.host,
            apiKey: document.getElementById('airsensor-map').getAttribute('clientid')
        }
    }).then(function (response) {
        return response.json()
    }).then(function (sensors) {
        buildMarkersLayer(sensors);
    });
    
    if (typeof myGiosSensorsUrl !== 'undefined' && (mapDiv.getAttribute('gios') === null || mapDiv.getAttribute('gios') === 'true')) {
        fetch(myGiosSensorsUrl, {
            headers: {
                site: document.getElementById('airsensor-map').getAttribute('site') || location.host,
                apiKey: document.getElementById('airsensor-map').getAttribute('clientid')
            }
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (sensors) {
                for (let i = 0; i < sensors.length; i++) {
                    if(!(maxBounds.contains(L.latLng(sensors[i].sensor.longitude, sensors[i].sensor.latitude)))){
                        continue;
                    }
                    let sensor = marker.createGiosMarker(sensors[i], mymap)
                        .bindPopup('', {closeButton: false, maxWidth: 1000})
                        .setPopupContent(sensors[i].sensor.name.replace(/\s/g, '&nbsp;'))
                        .on('click', giosMarkerClick);

                    markersLayer.addLayer(sensor);
                    globalGiosSensors[sensors[i].sensor.id] = sensor;
                }
            });
    }

    setTimeout(loadMapMarkers, 900000); // 900 sec = 15 min
};

addLogos();
addLegend();
loadMapMarkers();

