let leaflet = require('leaflet');
let markerIcons = require('./leaflet-icons');

const scale = markerIcons.scale;

let getMarkerColor = function (airClassName) {
    switch (airClassName) {
        case 'very-good':
            return '#4B9608';
        case 'good':
            return '#A2CE10';
        case 'moderate':
            return '#F0CB10';
        case 'satisfactory':
            return '#CE7200';
        case 'bad':
            return '#CB0000';
        case 'very-bad':
            return '#830000';
    }

    return '#606060';
};

let getSoilMarkerIcon = function (soilClassName) {
    switch (soilClassName) {
        case 'very-high':
            return markerIcons.soilMoistureVeryHigh;
        case 'high':
            return markerIcons.soilMoistureHigh;
        case 'moderate':
            return markerIcons.soilMoistureModerate;
        case 'low':
            return markerIcons.soilMoistureLow;
        case 'very-low':
            return markerIcons.soilMoistureVeryLow;
    }

    return markerIcons.soilMoistureVeryLow;
};

let getWaterLevelMarkerIcon = function(waterLevelClassName) {
    switch (waterLevelClassName) {
        case 'alarm':
            return markerIcons.waterLevelAlarm;
        case 'warning':
            return markerIcons.waterLevelWarning;
        case 'high':
            return markerIcons.waterLevelHigh;
        case 'average':
            return markerIcons.waterLevelAverage;
        case 'low':
            return markerIcons.waterLevelLow;
    }
    
    return markerIcons.waterLevelLow;
}

let getGiosIcon = function (giosClassName) {
    switch (giosClassName) {
        case 'very-good':
            return markerIcons.giosVeryGood;
        case 'good':
            return markerIcons.giosGood;
        case 'moderate':
            return markerIcons.giosModerate;
        case 'satisfactory':
            return markerIcons.giosSatisfactory;
        case 'bad':
            return markerIcons.giosBad;
        case 'very-bad':
            return markerIcons.giosVeryBad;
    }
    return markerIcons.giosNoData;
};

let createMarker = function (sensorData, map) {
    if (sensorData.sensor.type === 1) {
        if (sensorData.sensor.marker_ring === true) {
            L.circle([sensorData.sensor.longitude, sensorData.sensor.latitude], {
                color: 'green',
                fillColor: 'url(#' + sensorData.classifier.class + ')',
                fillOpacity: 0.7,
                radius: 1 * markerIcons.scale,
                stroke: false,
                id: sensorData.sensor.id,
            }).addTo(map).bringToBack();
        }

        return L.circleMarker([sensorData.sensor.longitude, sensorData.sensor.latitude], {
            title: sensorData.sensor.name + ' (' + sensorData.sensor.address + ')',
            id: sensorData.sensor.id,
            radius: 10 * markerIcons.scale,
            weight: 3 * markerIcons.scale,
            stroke: true,
            color: getMarkerColor(sensorData.classifier.class)
        }).addTo(map);
    } else if (sensorData.sensor.type === 2) {
        return L.marker([sensorData.sensor.longitude, sensorData.sensor.latitude], {
            title: sensorData.sensor.name + ' (' + sensorData.sensor.address + ')',
            id: sensorData.sensor.id,
            icon: getSoilMarkerIcon(sensorData.classifier.class)
        }).addTo(map);
    } else if (sensorData.sensor.type === 7) {
        return L.marker([sensorData.sensor.longitude, sensorData.sensor.latitude], {
            title: sensorData.sensor.name + ' (' + sensorData.sensor.address + ')',
            id: sensorData.sensor.id,
            icon: markerIcons.lifeBuoy
        }).addTo(map);
    } else {
        return L.marker([sensorData.sensor.longitude, sensorData.sensor.latitude], {
            title: sensorData.sensor.name + ' (' + sensorData.sensor.address + ')',
            id: sensorData.sensor.id,
            icon: getWaterLevelMarkerIcon(sensorData.classifier.class)
        }).addTo(map);
    }
};

let createGiosMarker = function (sensorData, map) {
    return L.marker([sensorData.sensor.longitude, sensorData.sensor.latitude], {
        title: sensorData.sensor.name + ' (' + sensorData.sensor.address + ')',
        id: sensorData.sensor.id,
        icon: getGiosIcon(sensorData.classifier.class)
    }).addTo(map);
};

let createIosMarker = function (sensorData, map) {
    return L.marker([sensorData.sensor.longitude, sensorData.sensor.latitude], {
        title: sensorData.sensor.name + ' (' + sensorData.sensor.address + ')',
        id: sensorData.sensor.id,
        icon: markerIcons.lifeBuoy
    }).addTo(map);
};

let createBicycleMarker = function (sensorData, map) {
    return L.marker([sensorData.sensor.longitude, sensorData.sensor.latitude], {
        title: sensorData.sensor.name + ' (' + sensorData.sensor.address + ')',
        id: sensorData.sensor.id,
        icon: markerIcons.bicycle
    }).addTo(map);
};

export default { createMarker, createGiosMarker, createBicycleMarker, getMarkerColor, scale};
